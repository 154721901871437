<template>
	<div
		:id="`contact-group-${_uid}`"
		:class="[
			'c-block-list-contact-group-custom mx-auto',

			{
				'c-block-list-contact-group-custom--wide >=1024:w-full':
					activeElements >= 3,
				'>=1024:w-8col': activeElements === 2,
				'>=1024:w-6col': activeElements === 1,
			},
		]"
	>
		<LongReadTarget
			v-if="title"
			:container-id="`contact-group-${_uid}`"
			:title="title"
		>
			<BaseH2
				class="text-primary-button mb-sm <1024:hidden"
				v-text="title"
			/>
		</LongReadTarget>

		<!-- DESKTOP -->
		<div
			v-if="contactgroup"
			:class="[
				'grid gap-x-sm gap-y-sm <1024:hidden grid-cols',

				{
					'>=1024:grid-cols-3': activeElements >= 3,
					'>=1024:grid-cols-2': activeElements === 2,
					'>=1024:grid-cols-1': activeElements === 1,
				},
			]"
		>
			<!-- PHONE SECTION -->
			<div
				v-if="showPhoneSection"
				class="
					bg-primary-button
					text-white
					rounded-lg
					p-md
					flex flex-col
					relative
				"
			>
				<BaseH3 class="mt-8">{{ titles.phoneTitle }}</BaseH3>
				<span class="mt-sm whitespace-pre-wrap">{{
					contactgroup.phoneText
				}}</span>
				<a
					v-if="contactgroup.phone"
					:href="`tel:${contactgroup.phone}`"
					class="mt-md flex items-center w-fit hover:underline"
				>
					<SvgPhoneIcon class="w-14 h-14 mr-12" />
					<span>{{ contactgroup.phone }}</span>
				</a>
				<div
					v-if="
						!contactgroup.phoneOpenHours.every(
							(item) => item.isOpen === false
						)
					"
					class="mt-sm flex items-baseline w-fit mb-xl"
				>
					<SvgClockIcon class="w-14 h-14 mr-12" />
					<div class="flex flex-col">
						<div
							v-for="(
								hours, index
							) in contactgroup.phoneOpenHours"
							:key="index"
							:class="hours.isOpen ? '' : 'hidden'"
						>
							<div
								v-if="hours.isOpen"
								class="flex justify-between"
							>
								<span>{{ hours.dayOfTheWeek }}</span>
								<div
									class="ml-xs"
									v-html="
										calculateHours(hours.hoursOfBusiness)
									"
								></div>
							</div>
						</div>
					</div>
				</div>
				<NuxtLinkExt
					v-if="contactgroup.phoneLink && contactgroup.phoneLink[0]"
					class="block w-fit absolute bottom-0"
					:to="contactgroup.phoneLink[0].url"
					:target="contactgroup.phoneLink.target"
				>
					<BaseButton tag="div" class="w-fit mb-md" outline="white-1">
						<template #default>
							{{ titles.phoneButtonTitle }}
						</template>
					</BaseButton>
				</NuxtLinkExt>
			</div>
			<!-- MEETUP SECTION  -->
			<div
				v-if="showMeetupSection"
				class="
					bg-primary-button
					text-white
					rounded-lg
					p-md
					flex flex-col
					relative
				"
			>
				<BaseH3 class="mt-8">{{ titles.meetTitle }}</BaseH3>
				<span class="mt-sm whitespace-pre-wrap">{{
					contactgroup.meetText
				}}</span>
				<div
					v-if="contactgroup.meetAddress.length > 0"
					class="mt-md flex items-start w-fit underline"
				>
					<SvgLocationIcon class="w-12 h-14 mr-12" />
					<div class="flex flex-col">
						<span
							v-for="(address, index) in contactgroup.meetAddress"
							:key="index"
							>{{ address }}</span
						>
					</div>
				</div>
				<div
					v-if="
						!contactgroup.meetOpenHours.every(
							(item) => item.isOpen === false
						)
					"
					class="mt-sm flex items-baseline w-fit mb-xl"
				>
					<SvgClockIcon class="w-14 h-14 mr-12" />
					<div class="flex flex-col">
						<div
							v-for="(hours, index) in contactgroup.meetOpenHours"
							:key="index"
							:class="hours.isOpen ? '' : 'hidden'"
						>
							<div
								v-if="hours.isOpen"
								class="flex justify-between"
							>
								<span>{{ hours.dayOfTheWeek }}</span>
								<div
									class="ml-xs"
									v-html="
										calculateHours(hours.hoursOfBusiness)
									"
								></div>
							</div>
						</div>
					</div>
				</div>
				<NuxtLinkExt
					v-if="contactgroup.meetLink && contactgroup.meetLink[0]"
					class="block w-fit absolute bottom-0"
					:to="contactgroup.meetLink[0].url"
					:target="contactgroup.meetLink.target"
				>
					<BaseButton tag="div" class="w-fit mb-md" outline="white-1">
						<template #default>{{
							titles.meetButtonTitle
						}}</template>
					</BaseButton>
				</NuxtLinkExt>
			</div>
			<!-- MAIL SECTION  -->
			<div v-if="showMailSection" class="flex flex-col">
				<!-- SAFEMAIL -->
				<div
					v-if="
						contactgroup.mailSafemail &&
						contactgroup.mailSafemail[0]
					"
					class="
						bg-secondary
						text-black
						rounded-lg
						p-md
						flex flex-col
						relative
						flex-1
					"
					:class="contactgroup.mailRegularForm ? 'mb-sm' : 'h-full'"
				>
					<BaseH3 class="mt-8">{{
						titles.mailSafeemailTitle
					}}</BaseH3>
					<span class="mt-sm mb-lg">{{
						titles.mailSafemailText
					}}</span>
					<NuxtLinkExt
						class="block w-fit bottom-0"
						:to="contactgroup.mailSafemail[0].url"
						:target="'_blank'"
					>
						<BaseButton tag="div" class="w-fit">
							<template #default>{{
								titles.mailSafeemailButtonTitle
							}}</template>
							<template #icon>
								<div class="flex">
									<SvgExternalArrowIcon
										class="w-14 h-14 mr-12"
									/>
									<SvgMitidIcon class="w-32 h-14" />
								</div>
							</template>
						</BaseButton>
					</NuxtLinkExt>
				</div>
				<!-- REGULARMAIL -->
				<div
					v-if="
						contactgroup.mailRegularForm &&
						contactgroup.mailRegularForm[0]
					"
					class="
						bg-secondary
						text-black
						rounded-lg
						p-md
						flex flex-col flex-1
						relative
					"
					:class="contactgroup.mailSafemail ? '' : 'h-full'"
				>
					<BaseH3 class="mt-8">{{ titles.mailRegularTitle }}</BaseH3>
					<span class="mt-sm mb-lg">{{
						titles.mailRegularText
					}}</span>
					<NuxtLinkExt
						class="block w-fit bottom-0"
						:to="mailLink"
						:target="'_self'"
					>
						<BaseButton tag="div" class="w-fit">
							<template #default>{{
								titles.mailRegularButtonTitle
							}}</template>
						</BaseButton>
					</NuxtLinkExt>
				</div>
			</div>
		</div>
		<!-- MOBILE -->
		<div class=">=1024:hidden">
			<AccordionLayout v-bind="{ title, elements, groupkey }">
				<template #header="{ item }">
					<BaseH3 tag="h3" v-text="item.title"></BaseH3>
				</template>

				<template #panel="{ item }">
					<div class="relative h-full pb-xl">
						<span>{{ item.teaser }}</span>
						<a
							v-if="item.phone"
							:href="`tel:${item.phone}`"
							class="
								mt-md
								flex
								items-center
								w-fit
								hover:underline
							"
						>
							<SvgPhoneIcon class="w-14 h-14 mr-12" />
							<span>{{ item.phone }}</span>
						</a>
						<div
							v-if="item.location && item.location.length > 0"
							class="mt-md flex items-center w-fit underline"
						>
							<SvgLocationIcon class="w-12 h-14 mr-12" />
							<span>{{ item.location.join(' ') }}</span>
						</div>
						<div
							v-if="
								item.hours &&
								!item.hours.every(
									(thing) => thing.isOpen === false
								)
							"
							class="mt-sm flex items-baseline w-fit"
						>
							<SvgClockIcon class="w-14 h-14 mr-12" />
							<div class="flex flex-col">
								<div
									v-for="(hours, index) in item.hours"
									:key="index"
									:class="hours.isOpen ? '' : 'hidden'"
								>
									<div
										v-if="hours.isOpen"
										class="flex justify-between"
									>
										<span>{{ hours.dayOfTheWeek }}</span>
										<div
											class="ml-xs"
											v-html="
												calculateHours(
													hours.hoursOfBusiness
												)
											"
										></div>
									</div>
								</div>
							</div>
						</div>
						<NuxtLinkExt
							v-if="item && item.url"
							class="block w-fit absolute bottom-0"
							:to="item.url"
							:target="'_self'"
						>
							<BaseButton
								tag="div"
								class="w-fit"
								:outline="
									item.phone || item.location
										? 'white-1'
										: 'none'
								"
							>
								<template #default>{{ item.button }}</template>
								<template
									v-if="item.myid || item.external"
									#icon
								>
									<div class="flex">
										<SvgExternalArrowIcon
											v-if="item.myid || item.external"
											class="w-12 h-12"
										/>
										<SvgMitidIcon
											v-if="item.myid"
											class="w-32 h-14 ml-12"
										/>
									</div>
								</template>
							</BaseButton>
						</NuxtLinkExt>
					</div>
				</template>
			</AccordionLayout>
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs';

import SvgPhoneIcon from '~/assets/svgs/phone-icon.svg?inline';
import SvgClockIcon from '~/assets/svgs/clock-icon.svg?inline';
import SvgLocationIcon from '~/assets/svgs/location-icon.svg?inline';
import SvgExternalArrowIcon from '~/assets/svgs/external-arrow-icon.svg?inline';
import SvgMitidIcon from '~/assets/svgs/mitid-icon.svg?inline';
import AccordionLayout from '~/components/shared/AccordionLayout';
import LongReadTarget from '~/citi-baseline/components/LongReadTarget';

export default {
	name: 'BlockListContactGroupCustom',
	components: {
		SvgPhoneIcon,
		SvgClockIcon,
		SvgExternalArrowIcon,
		SvgMitidIcon,
		SvgLocationIcon,
		AccordionLayout,
		LongReadTarget,
	},
	inheritAttrs: false,

	props: {
		title: String,
		titles: Object,
		contactgroup: Object,
		mailLink: String,
		showMailSection: Boolean,
		showMeetupSection: Boolean,
		showPhoneSection: Boolean,
	},
	computed: {
		activeElements() {
			const sections = [
				{ show: this.showMailSection },
				{ show: this.showMeetupSection },
				{ show: this.showPhoneSection },
			];
			const activeSections = sections.filter(
				(e) => e.show === true
			).length;
			return activeSections;
		},
		elements() {
			if (this.contactgroup) {
				const sections = [
					{
						key: `${this.contactgroup.key}-phone`,
						title: this.titles.phoneTitle,
						show: this.showPhoneSection,
						theme: 'primary',
						teaser: this.contactgroup.phoneText,
						phone: this.contactgroup.phone,
						location: null,
						hours: this.contactgroup.phoneOpenHours,
						button: this.titles.phoneButtonTitle,
						url: this.contactgroup.phoneLink?.[0]?.url,

						myid: false,
						external: this.isExternal(
							this.contactgroup.phoneLink?.[0]?.url
						),
					},
					{
						key: `${this.contactgroup.key}-meet`,
						title: this.titles.meetTitle,
						show: this.showMeetupSection,
						theme: 'primary',
						teaser: this.contactgroup.meetText,
						phone: null,
						location: this.contactgroup.meetAddress,
						hours: this.contactgroup.meetOpenHours,
						button: this.titles.meetButtonTitle,
						url: this.contactgroup.meetLink?.[0]?.url,

						myid: false,
						external: this.isExternal(
							this.contactgroup.meetLink?.[0]?.url
						),
					},
					{
						key: `${this.contactgroup.key}-safeemail`,
						title: this.titles.mailSafeemailTitle,
						show: !!(
							this.showMailSection &&
							this.contactgroup.mailSafemail
						),
						theme: 'secondary',
						teaser: this.contactgroup.mailSafemailText,
						phone: null,
						location: null,
						hours: null,
						button: this.titles.mailSafeemailButtonTitle,
						url: this.contactgroup.mailSafemail?.[0]?.url,

						myid: true,
						external: this.isExternal(
							this.contactgroup.mailSafemail?.[0]?.url
						),
					},
					{
						key: `${this.contactgroup.key}-regularmail`,
						title: this.titles.mailRegularText,
						show: !!(
							this.showMailSection &&
							this.contactgroup.mailRegularForm
						),
						theme: 'secondary',
						teaser: this.contactgroup.mailRegularText,
						phone: null,
						location: null,
						hours: null,
						button: this.titles.mailRegularButtonTitle,
						url: this.contactgroup.mailRegularForm?.[0]?.url,

						myid: false,
						external: this.isExternal(
							this.contactgroup.mailRegularForm?.[0]?.url
						),
					},
				];
				const activeSections = sections.filter((e) => e.show === true);
				return activeSections;
			}

			return [];
		},
		groupkey() {
			return this.contactgroup.key;
		},
	},

	methods: {
		calculateHours(hours) {
			if (hours?.length === 1) {
				const openTime = dayjs(hours.map((a) => a.opensAt)).format(
					'HH.mm'
				);
				const closeTime = dayjs(hours.map((a) => a.closesAt)).format(
					'HH.mm'
				);
				return `Kl. ${openTime} - ${closeTime}`;
			} else if (hours?.length > 1) {
				const openTime1 = dayjs(hours[0].opensAt).format('HH.mm');
				const closeTime1 = dayjs(hours[0].closesAt).format('HH.mm');
				const openTime2 = dayjs(hours[1].opensAt).format('HH.mm');
				const closeTime2 = dayjs(hours[1].closesAt).format('HH.mm');

				return `Kl. ${openTime1} - ${closeTime1} </br> Kl. ${openTime2} - ${closeTime2}`;
			} else {
				return '';
			}
		},

		/** Helper functions */
		isExternal(url) {
			if (url) {
				if (
					['http://', 'https://', 'ftp://'].some(
						(str) => url.indexOf(str) === 0
					)
				) {
					return true;
				}

				const splitOut = url.split('/');
				if (splitOut[0].indexOf('.') >= 0) {
					return true;
				}
			}

			return false;
		},
	},
};
</script>

<style lang="postcss">
/**
	Forces white color, because SiteImprove is
	weird.
 */
.c-block-list-contact-group-custom .text-white,
.c-block-list-contact-group-custom .text-white * {
	color: #ffffff !important;
}
</style>
